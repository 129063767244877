<script>
import { insertQna, updateQna } from '@/api/platform/community'
import TextEditorVue from '@/components/TextEditor.vue'
import { validateRegistLabName } from '@/util/reg-validators'

export default {
  components: { TextEditorVue },
  data() {
    return {
      boardData: {
        boardName: 'qna',
        boardId: 0
      },
      qna: {
        userId: this.$store.state.userStore.info.userId,
        content: ' ',
        isSecret: 0,
        title: ''
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    routeReplace() {
      const path = this.$route.path
      const listUrl = path.slice(0, path.lastIndexOf('/'))
      this.$router.replace(listUrl)
    },
    async saveQna() {
      if (!this.qna.title) return alert('제목을 입력해야합니다.')
      if (!validateRegistLabName(this.removeSpaces(this.qna.title))) {
        return alert('제목은 한글, 영어, 숫자만 입력가능합니다.')
      }

      const result = await insertQna(this.qna)
      if (result?.code) return alert(result.message)

      this.boardData.boardId = result.qnaIds
      await this.$refs.editor.saveContent()
    },

    async getEditorData({ getHtmlAfterSaveImages }) {
      const content = await getHtmlAfterSaveImages(this.boardData)

      const updatedResult = await updateQna({ content }, this.boardData.boardId)
      if (updatedResult?.code) return alert(updatedResult.message)

      alert('저장되었습니다.')
      this.routeReplace()
    },

    removeSpaces(str) {
      return str.replaceAll(/[ ]/g, '')
    }
  },

  mounted() {}
}
</script>

<template>
  <section class="wf__qna-write">
    <article class="wf__qna-head">
      <h2 class="wf__qna-title">QnA</h2>
      <a class="wf__qna-back-btn" href="#" @click="goBack">뒤로가기</a>
    </article>

    <div class="divider"></div>

    <form class="wf__qna-form" autocomplete="off">
      <div class="wf__qna-input-container">
        <!-- <div class="wf__qna-input-wrapper">
          <label class="wf__qna-input-label">이름</label>
          <input v-if="user" v-model="user.name" type="text" name="name" class="wf__qna-input" placeholder="이름" readonly />
        </div> -->
        <!-- <div class="wf__qna-input-wrapper">
          <label class="wf__qna-input-label">이메일</label>
          <input v-model="qna.email" type="text" name="email" class="wf__qna-input" placeholder="이메일" />
        </div> -->
        <div class="wf__qna-input-wrapper">
          <label class="wf__qna-input-label">제목</label>
          <input v-model="qna.title" type="text" name="title" class="wf__qna-input" placeholder="제목" />
        </div>
      </div>

      <TextEditorVue
        class="wf__qna-text-editor"
        :hideSave="true"
        height="50vh"
        ref="editor"
        @emitData="getEditorData"
        :boardData="boardData"
        :preventImage="true"
      />

      <div class="wf__qna-input-foot">
        <span>비밀글</span>
        <div class="check-box-area">
          <div class="round">
            <input v-model="qna.isSecret" type="radio" name="agree" value="1" id="agree11" />
            <label class="round-checkbox" for="agree11"></label>
            <label class="cursor-pointer" for="agree11"><span>예</span></label>
          </div>
          <div class="round">
            <input v-model="qna.isSecret" type="radio" name="agree" value="0" id="agree21" />
            <label class="round-checkbox" for="agree21"></label>
            <label class="cursor-pointer" for="agree21"><span>아니오</span></label>
          </div>
        </div>
      </div>

      <div class="wf__qna-input-submit-wrapper">
        <button class="wf__qna-input-submit" type="button" @click="saveQna">저장</button>
      </div>
    </form>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wf__qna-write {
  font-size: var(--wf-text-16);
  text-align: left;
  margin: 3rem;
}

.wf__qna-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.wf__qna-title {
  font-size: 2.5rem;
  font-weight: bold;
}
.wf__qna-back-btn {
  color: var(--wf-primary-color);
  transition: all 0.2s ease;
}
.wf__qna-back-btn:hover {
  opacity: 0.75;
}

.divider {
  height: 2px;
  background-color: var(--wf-gray-color);
  margin: 2rem 0;
}

.wf__qna-form {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}
.wf__qna-input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.wf__qna-input-wrapper {
  display: flex;
  align-items: center;
}
.wf__qna-input-label {
  flex-basis: 4em;
}
.wf__qna-input {
  outline: none;
  border: 1px solid var(--wf-gray-color);

  padding: 0.25em 0.5em;
  border-radius: 0.25rem;
}
.wf__qna-input:read-only {
  background-color: var(--wf-gray-600);
  color: var(--wf-secondary-color);
}
.wf__qna-input[name='email'] {
  width: 20em;
}
.wf__qna-input[name='title'] {
  width: 50em;
}

.wf__qna-text-editor {
  max-width: 100% !important;
  width: 100%;
}

.wf__qna-input-foot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}
.round {
  position: relative;
  float: left;
  padding: 0px 10px 0px 10px;
}
.round .round-checkbox {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.round input.invalid ~ .round-checkbox {
  border-color: var(--wf-error-color);
}
.round .round-checkbox:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  width: 12px;
  left: 4px;
  top: 5px;
  position: absolute;
  opacity: 0;
  transform: rotate(-45deg);
}
.round input[type='radio'] {
  visibility: hidden;
}
.round input[type='radio']:checked + .round-checkbox {
  background-color: var(--wf-primary-color);
  border-color: var(--wf-misty-color);
}
.round input[type='radio']:checked + .round-checkbox:after {
  opacity: 1;
}

.wf__qna-input-submit {
  padding: 0.25em 1em;
  outline: none;
  border: none;
  border-radius: 0.25em;

  color: var(--wf-misty-color);
  background-color: var(--wf-primary-color);
  transition: opacity 0.2s ease;
}
.wf__qna-input-submit:hover {
  opacity: 0.75;
}

.wf__qna-password-input {
  padding: 0.25em 0.5em;
  margin-left: 1.5rem;
}
.check-box-area {
  margin-left: 1.5rem;
}
</style>
